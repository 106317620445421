<template>
  <div class="vip">
    <div class="vip-center position-relative">
      <div class="center-posi position-absolute top-0 left-0">
        <div class="center-box row">
          <div class="box-left">
            <div class="left-heard position-relative">
              <img src="../../../assets/bg-img/vip1.png" alt="" />
              <div class="heard-text position-absolute left-0 top-0 flex-center">
                <div class="font-bold">招聘工具搭配使用，招人更快！</div>
                <div class="font-normal flex-center">
                  组合套餐，高性价、低成本，更多组合
                  <span class="text-blue pointer" @click="connectClick">
                    请招聘顾问联系我</span
                  >
                </div>
              </div>
            </div>
            <div class="vip-item">
              <div
                class="vip-base position-relative pointer"
                :class="vipUpgradeNum == 1 ? 'baseBg' : ''"
                @click="upgradeClick(1)"
              >
                <div class="base-center flex-center" v-if="vipList">
                  <div class="direction">
                    <span class="font-light">人才推送人数</span>
                    <span class="text-main font-bold">{{
                      vipList.basic_meal.push_num
                    }}</span>
                  </div>
                  <div class="direction">
                    <span class="font-light">刷新次数</span>
                    <span class="text-main font-bold">{{
                      vipList.basic_meal.refresh_num
                    }}</span>
                  </div>
                  <div class="direction">
                    <span class="font-light">置顶天数</span>
                    <span class="text-main font-bold">{{
                      vipList.basic_meal.top_num
                    }}</span>
                  </div>
                  <div class="direction">
                    <span class="font-light">急招天数</span>
                    <span class="text-main font-bold">{{
                      vipList.basic_meal.recruit_num
                    }}</span>
                  </div>
                </div>
                <div
                  class="position-absolute left-0 base-right-text flex-center text-white"
                >
                  基础版
                </div>
                <div
                  class="position-absolute right-0 top-0 base-right-img"
                  v-if="vipUpgradeNum == 1"
                >
                  <img src="../../../assets/bg-img/vip6.png" alt="" />
                </div>
              </div>
              <div
                class="vip-base position-relative vip-upgrade pointer"
                @click="upgradeClick(2)"
              >
                <div class="base-center flex-center" v-if="vipList">
                  <div class="direction">
                    <span class="font-light">人才推送人数</span>
                    <span class="text-main font-bold">{{
                      vipList.upgrade_meal.push_num
                    }}</span>
                  </div>
                  <div class="direction">
                    <span class="font-light">刷新次数</span>
                    <span class="text-main font-bold">{{
                      vipList.upgrade_meal.refresh_num
                    }}</span>
                  </div>
                  <div class="direction">
                    <span class="font-light">置顶天数</span>
                    <span class="text-main font-bold">{{
                      vipList.upgrade_meal.top_num
                    }}</span>
                  </div>
                  <div class="direction">
                    <span class="font-light">急招天数</span>
                    <span class="text-main font-bold">{{
                      vipList.upgrade_meal.recruit_num
                    }}</span>
                  </div>
                </div>
                <div
                  class="position-absolute left-0 base-right-text flex-center text-white"
                >
                  升级版
                </div>
                <div
                  class="position-absolute right-0 top-0 base-right-img"
                  v-if="vipUpgradeNum == 2"
                >
                  <img src="../../../assets/bg-img/vip7.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="box-right one direction">
            <div class="box-right-price position-relative">
              <img src="../../../assets/bg-img/vip2.png" alt="" />
              <div class="price-text position-absolute top-0 left-0 direction">
                <div class="price-text-many space-between">
                  <span class="text-main font-lighter">需支付</span>
                  <span v-if="vipUpgradeNum == 1 && vipList" class="font-bold"
                    >￥{{ vipList.basic_meal.price }}</span
                  >
                  <span v-if="vipUpgradeNum == 2 && vipList" class="font-bold"
                    >￥{{ vipList.upgrade_meal.price }}</span
                  >
                </div>
                <div class="border-price"></div>
                <div class="price-text-many-one space-between text-main font-normal">
                  <span>需支付</span>
                  <span v-if="vipUpgradeNum == 1 && vipList"
                    >￥{{ vipList.basic_meal.price }}</span
                  >
                  <span v-if="vipUpgradeNum == 2 && vipList"
                    >￥{{ vipList.upgrade_meal.price }}</span
                  >
                </div>
              </div>
            </div>
            <div class="flex-center pay-tab">
              <div
                class="flex-center pointer"
                :class="[payActiveIndex == 3 ? 'pay-item-active' : '']"
                @click="payClick(3)"
              >
                支付宝
              </div>
              <div
                class="flex-center pointer"
                :class="[payActiveIndex == 4 ? 'pay-item-active' : '']"
                @click="payClick(4)"
              >
                微信
              </div>
            </div>
           <div class="flex-center" style="margin-bottom:10px" v-if="payActiveIndex == 3">
              <el-button type="primary" plain @click="zfbClick">点击去支付</el-button>
            </div>
            <div v-if="payActiveIndex==4 && buyObj">
              <img :src="buyObj.payment.qrcode_url" class="price-code" alt="" />
              <div class="price-text-code flex-center">二维码有效期至</div>
              <div class="price-text-code flex-center">{{buyObj.failure_time}}</div>
              <div class="price-text-code row-center code-text-img flex-center">
                <img src="../../../assets/glxt/16.png" class="wx-img" alt="" />
                <span>扫码支付</span>
              </div>
            </div>
            <div class="font-lighter peice-xy">
              付费即表示同意
              <span class="text-blue pointer" @click="serveClick">《干纺织增值服务协议》</span>
            </div>
            <div class="text-blue pointer" @click="consultantClick">我要对公转账汇款</div>
          </div>
        </div>
      </div>
      <div class="posi-img position-absolute top-0 left-0">
        <img
          src="../../../assets/bg-img/vip4.png"
          alt=""
          class="img-bg position-absolute bottom-0"
        />
      </div>
    </div>
    <mask-model
      ref="mask"
      title="招聘顾问会尽快与您联系，请耐心等待"
      :btnCancel="false"
      width="460px"
      @submitClick="feedback"
    >
      <div class="connetc-class"></div>
    </mask-model>
    <!-- 对公转账弹窗 -->
    <consultant ref="consultant"></consultant>
    <mask-serve ref="maskServe"></mask-serve>
  </div>
</template>

<script>
import maskModel from "../../../components/mask.vue";
import consultant from "../../../components/consultant.vue";
import maskServe from "../../../components/server.vue"
import api from "../../../api/user";
import toolApi from "../../../api/tool"
import { mapState } from "vuex";
export default {
  components: { maskModel, consultant,maskServe },
 computed: {
    // 2. 解构状态作为计算属性
    ...mapState(["entDetail"])
  },
  data() {
    return {
      // 选择基础班还是升级版
      vipUpgradeNum: 1,
      status: false,
      vipList: null,
      payActiveIndex: 1,
      query:{
        type:1,
        pay_type:3
      },
      buyObj:null,
      setTime:null,
      setObj:null
    };
  },

  created() {
    this.getPlatFrom();
    this.setObj=JSON.parse(localStorage.getItem('setObj'))
  },

  mounted() {},
  beforeDestroy(){
    clearInterval(this.setTime)
  },
  methods: {
    feedback(){
      let query={
        content:`${this.entDetail.ent_name},我需要了解更多组合套餐，请联系我！`,
        is_contact:1
      }
       toolApi.feedback(query).then((res) => {
        if (res.code == 200) {
          this.$refs.mask.hide();
          this.$util.msg("联系成功，客服稍后会与你联系");
        }
      });
    },
    serveClick(){
      this.$refs.maskServe.show()
    },
    getPlatFrom() {
       const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      api.getPlatFrom().then((res) => {
        if (res.code == 200) {
          this.vipList = res.data.vip;
        }
        loading.close()
      });
    },
    upgradeClick(index) {
      this.vipUpgradeNum = index;
      this.query.type=index
      // this.vipClick()
    },
    hide() {
      this.status = false;
    },
    // 轮训查看是否支付成功
    setInter(){
     this.setTime= setInterval(() => {
        api.searchOrder({order_no:this.buyObj.order_no}).then(res=>{
          
        if(res.code==200){
          if(res.data.pay_status==1){
            clearInterval(this.setTime)
            setTimeout(()=>{
              this.$util.msg('支付成功')
            },1000)
          }
        }
      })
      }, 1000);
    },
    vipClick(){
      api.buyVip(this.query).then(res=>{
         if (res.code == 200) {
          clearInterval(this.setTime)
          this.buyObj = res.data;
          this.setInter()
        }
      })
    },
    // 联系顾问
    connectClick() {
      this.$refs.mask.show();
    },
    // 对公转账
    consultantClick() {
      this.$refs.consultant.show();
    },
    payClick(index) {
      if(index==3 && this.setObj.pay.alipay!=1){
        this.$util.msg('支付宝支付未开启，请选择微信支付','error')
        return
      }else if(index==4 && this.setObj.pay.wx!=1){
        this.$util.msg('微信支付未开启，请选择支付宝支付','error')
        return
      }
      clearInterval(this.setTime)
      this.query.pay_type=index
      this.payActiveIndex = index;
      this.vipClick()
    },
    zfbClick(){
       let divForm = document.getElementsByTagName("divform");
      if (divForm.length) {
        document.body.removeChild(divForm[0]);
      }
      const div = document.createElement("divform");
      div.innerHTML = this.buyObj.payment;
      document.body.appendChild(div);
      document.forms[0].setAttribute("target", "_blank");
      document.forms[0].submit();
    }
  },
};
</script>
<style lang="less" scoped>
.vip {
  width: 100%;
  padding: 1.25rem 9.125rem 2.375rem 9.125rem;
  .vip-center {
    width: 100%;
    background: #fff;
    height: 53.8rem;
    .posi-img {
      width: 100%;
      height: 100%;
      z-index: 0;
      .img-bg {
        width: 72.4375rem;
        left: 9.0625rem;
      }
    }
    .center-posi {
      width: 100%;
      padding: 5.3125rem 3.4375rem 2.375rem 3.5rem;
      z-index: 2;
    }
    .center-box {
      width: 100%;
      background: #ffffff;
      box-shadow: -1px 0px 17px 0px rgba(0, 0, 0, 0.06);
      .box-left {
        width: 53rem;
        .left-heard {
          width: 100%;
          height: 9.4375rem;
          margin-bottom: 4.375rem;
          img {
            width: 100%;
            height: 9.4375rem;
          }
          .heard-text {
            width: 100%;
            height: 100%;
            color: #222222;
            div:last-child {
              margin-left: 0.625rem;
              width: 24.1875rem;
              height: 1.9375rem;
              background: #ffecd9;
              border-radius: 1rem;
              span {
                display: inline-block;
                margin-left: 6px;
              }
            }
          }
        }
        .vip-item {
          width: 100%;
          padding-left: 3.375rem;
          padding-right: 4.3125rem;
          .vip-base {
            width: 100%;
            height: 13rem;
            background: #ffffff;
            border: 2px solid #126bf9;
            border-radius: 1.125rem;
            margin-bottom: 75px;
            .base-center {
              width: 100%;
              height: 100%;
              justify-content: space-evenly;
              color: #9fa3b0;
              div {
                height: 100%;
                padding: 3.75rem 0;
                justify-content: space-between;
              }
            }
            .base-right-text {
              width: 5.25rem;
              height: 1.9375rem;
              background: linear-gradient(0deg, #4b87ff 0%, #71a0ff 100%);
              border-radius: 8px 8px 8px 0px;
              font-size: 15px;
              top: -15px;
              left: -2px;
            }
            .base-right-img {
              right: -2px;
              top: -2px;
              img {
                width: 79px;
                height: 79px;
              }
            }
          }
          .vip-upgrade {
            margin-bottom: 40px;
            border: 2px solid #fef1e0;
            .base-right-text {
              background: linear-gradient(0deg, #e3ac7f 0%, #f7cfab 100%);
            }
          }
          .baseBg {
            background: #f9fbff;
          }
        }
      }
      .box-right {
        background: #fafafa;
        padding-top: 4.75rem;
        .box-right-price {
          width: 24.0625rem;
          height: 12.75rem;
          margin-bottom: 5.9375rem;
          img {
            width: 100%;
            height: 100%;
          }
          .price-text {
            width: 100%;
            height: 100%;
            padding: 0 0.8125rem;
            justify-content: space-evenly;
            div {
              width: 100%;
            }
            .price-text-many-one,
            .price-text-many {
              padding-left: 13px;
              padding-right: 10px;
            }
            .price-text-many span:last-child {
              color: #fc6c38;
            }
            .border-price {
              border-bottom: 1px dashed #535353;
            }
          }
        }
        .price-code {
          width: 8.9375rem;
          height: 8.9375rem;
          margin-bottom: 0.625rem;
        }
        .pay-tab {
          width: 100%;
          margin-bottom: 10px;
          div {
            width: 4.5625rem;
            height: 2.125rem;
            border: 0.0625rem solid #dbdfe4;
            border-radius: 0.125rem;
            color: #9fa3b0;
            margin-right: 1.25rem;
          }
          .pay-item-active {
            border: 1px solid #126bf9;
            color: #126bf9;
          }
        }
        .price-text-code {
          color: #a3a3a3;
          font-size: 13px;
          img {
            width: 19px;
            height: 19px;
          }
          .wx-img {
            margin: 0 7px;
          }
        }
        .code-text-img {
          margin-top: 3px;
          margin-bottom: 19px;
        }
        .peice-xy {
          color: #666d80;
          margin-bottom: 1.625rem;
        }
        > div:last-child {
          font-weight: bold;
          font-size: 15px;
        }
      }
    }
  }
  .connetc-class {
    width: 100%;
    height: 45px;
  }
}
</style>
